<template>
  <div id="app">
    <div class="top-bar">
      <h1>Che Inu Photography</h1>
      <div id="nav">
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/contact">Contact</router-link>
      </div>
  </div>
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.temptrans || 'fade'">
          <component :is="Component" />
        </transition>
      </router-view>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";

export default{
  setup() {
    const router = useRouter();
    const route = useRoute();

    router.afterEach((to, from) => {
      //const toDepth = to.path.split('/').length
      const toDepth = to.meta.depth;
      //const fromDepth = from.path.split('/').length
      const fromDepth = from.meta.depth;
      to.meta.temptrans = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      console.log(route.meta.temptrans);
    });
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Peddana&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Adamina&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0px;
}

h1 {
  font-size: 2.5vw;
  margin: 0px;
  padding-right: 10vw;
  text-align: left;
}
#app {
  margin: 0px;
  padding: 0px;
  font-family: "Adamina", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-attachment: fixed;
}

.top-bar {
  display: flex;
  padding-left: 2vw;
  padding-top: 2vh;
  padding-bottom: 3vh;
  align-items: center;
  position: fixed;
  background-color: white;
  z-index: 99;
  width: 100vw;
}

#nav a {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  margin-right: 5vw;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #62bbc7;
}
</style>
