<template>
  <div class="foreground-card">
    <div v-masonry="Gallery" class="gallery" transition-duration="0.1s" item-selector=".div-image"> 
      <div v-masonry-tile class="div-image" v-for="image in images" v-bind:key="image.pathLong">
        <img class="each-image" ref="thisimage" :src="image.pathLong" @click="onClick"/>
      </div>
    </div>
  </div>

    <div id="modal01" class="w3-modal" @click="clickRemove">
      <img class="w3-modal-content" id="img01" style="width:80%"/>
    </div>
    <div id="modal-background" class="modal-background" @click="clickRemove"></div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data() {
    return {
      images: []
    };
  },
  mounted() {
    this.importAll(require.context("../assets/", true, /\.png$/));
  },

  methods: {
    importAll(r) {
      //console.log(r)
      r.keys().forEach(key =>
        (this.images.push({ pathLong: r(key), pathShort: key }))
      );
    },

    onClick(element) {
      console.log(element.srcElement.src)
      document.getElementById("img01").src = element.srcElement.src;
      document.getElementById("modal01").style.display = "block";
      document.getElementById("modal-background").style.display = "block";
      console.log(document.getElementById("modal-background").style.display)
    },

    clickRemove() {
      console.log("removed");
      document.getElementById("modal01").style.display = "none";
      document.getElementById("modal-background").style.display = "none";
    },

    printshit(shit) {
      console.log(shit);
    },

    printthing() {
      console.log(this.images);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.foreground-card {
  padding-top: 12vh;
  margin-right:4vw;
  margin-left: 4vw;
  background-color: rgb(255, 255, 255);
}

.gallery {
  margin-right: 2vw;
  margin-left: 2vw;
}

.each-image {
  max-width: 20vw;
  margin: 0.5vw;
  box-shadow: 5px 5px 5px rgb(175, 175, 175);
}

.modal-background {
  margin: 0px;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  background-color: black;
  background-size: 100%;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.w3-modal{
  margin: 0px;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  transition: height 1s, width 1s;
}

.w3-modal-content {
  margin: 0;
  padding: 2vw;
  background-color: white;
  user-select: none;
}
/*
.image-container {
  margin: 5px;
  border: solid;
  border-width: 1px;
  min-width: 50vh;
  min-height: 50vh;
  border-color:black;
}
/*
.Gallery{
  display: grid;
  grid-template-columns: auto auto auto;
  flex-wrap: wrap;
  justify-content: center;
}
*/
</style>
